<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <b-img
          fluid
          :src="imgLogo2"
          alt="Logo New X Coins"
          style="max-height: 8rem"
        />
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex remove-margin">
        <b-img fluid :src="imgUrl" alt="ForgotPassword V2" />
      </b-col>
      <!-- /Left Text-->

      <!-- Reset password-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div v-if="invalidSignature == false">
            <b-card-title title-tag="h2" class="font-weight-bold mb-1">
              {{ $t("Bem-vindo!") }} 👋
            </b-card-title>
            <b-card-text class="mb-2">
              {{ $t("Digite sua nova senha para acessar a plataforma...") }}
            </b-card-text>

            <!-- form -->
            <validation-observer ref="simpleRules" #default="{ invalid }">
              <b-form
                class="auth-reset-password-form mt-2"
                method="POST"
                @submit.prevent="validationForm"
              >
                <!-- password -->
                <b-form-group
                  :label="labelNewPassword"
                  label-for="reset-password-new"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="NewPassword"
                    vid="new-password"
                    rules="required|min:4"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="reset-password-new"
                        v-model="password"
                        :type="password1FieldType"
                        :state="errors.length > 0 ? false : null"
                        class="form-control-merge"
                        name="reset-password-new"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password1ToggleIcon"
                          @click="togglePassword1Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- confirm password -->
                <b-form-group
                  label-for="reset-password-confirm"
                  :label="labelRetypeNewPassword"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="RetypePassword"
                    vid="retype-password"
                    rules="required|min:4|confirmed:new-password"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid' : null"
                    >
                      <b-form-input
                        id="reset-password-confirm"
                        v-model="cPassword"
                        :type="password2FieldType"
                        class="form-control-merge"
                        :state="errors.length > 0 ? false : null"
                        name="reset-password-confirm"
                        placeholder="············"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="password2ToggleIcon"
                          @click="togglePassword2Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit button -->
                <b-button
                  block
                  type="submit"
                  variant="primary"
                  :disabled="invalid || loading"
                >
                  {{ $t("Definir nova senha") }}
                </b-button>
              </b-form>
            </validation-observer>
          </div>
          <div
            v-if="invalidSignature == true"
            class="animate__animated animate__fadeIn"
          >
            <sweetalert-icon
              icon="error"
              class="animate__animated animate__backInDown"
            />
            <b-card-title class="mb-1 text-danger">
              {{ $t("Link de confirmação inválido!") }}
            </b-card-title>
            <b-card-text class="mb-2">
              {{
                $t(
                  "Esse link para confirmação do seu e-mail não é mais válido, confira em sua caixa de entrada se não recebeu um link atualizado ou entre em contato com o suporte."
                )
              }}
            </b-card-text>
          </div>

          <p
            class="text-center mt-2"
            v-if="invalidSignature == (true || false)"
          >
            <b-link :to="{ name: 'login' }">
              <feather-icon icon="ChevronLeftIcon" /> {{ $t("Volte ao login") }}
            </b-link>
          </p>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ProjectLogo from "@/layouts/logo/LogoProjectX.vue";
import {
  BRow,
  BCol,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BImg,
} from "bootstrap-vue";
import { required, confirmed } from "@/libs/validations";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    ProjectLogo,
    BRow,
    BCol,
    BButton,
    BCardTitle,
    BCardText,
    BForm,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      cPassword: "",
      password: "",
      sideImg: require("@/assets/images/pages/reset-password-v2.svg"),
      labelNewPassword: this.$i18n.t("Nova senha"),
      labelRetypeNewPassword: this.$i18n.t("Digite novamente a nova senha"),
      loading: false,
      emailSent: false,
      invalidSignature: null,

      // validation
      required,

      // Toggle Password
      password1FieldType: "password",
      password2FieldType: "password",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      return require("@/assets/images/business_plan/forgot_password.jpg");
    },
    imgLogo() {
      if (store.state.appConfig.layout.skin === "dark") {
        return require("@/assets/images/logo/newxcoins_logo_light.png");
      } else {
        return require("@/assets/images/logo/newxcoins_logo_dark.png");
      }
    },
    imgLogo2() {
      return require("@/assets/images/logo/newxcoins_logo_light.png");
    },
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  mounted() {
    this.$store
      .dispatch(
        "verifySignature",
        this.$route.path + "?signature=" + this.$route.query.signature
      )
      .then((response) => {
        this.invalidSignature = false;
      })
      .catch((err) => {
        this.invalidSignature = true;
      });
  },
  methods: {
    ...mapActions(["newPassword"]),
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          //
          this.loading = true;
          const data = {
            routerRedefinePsw:
              this.$route.path + "?signature=" + this.$route.query.signature,
            password: this.password,
            password_confirmation: this.cPassword,
          };
          this.newPassword(data)
            .then((response) => {
              this.$ability.update(response.data.users.roles);
              this.loading = false;
              this.$router.push("/");
            })
            .catch((error) => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  icon: "CoffeeIcon",
                  variant: "danger",
                  title: this.$i18n.t("Erro na autenticação"),
                  text: error,
                },
              });
              this.emailError = error;
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.remove-margin {
  padding-right: 0rem !important;
  padding-left: 0rem !important;
}
</style>
